import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './components/shared/Layout';
import { Contact } from './components/Content/contact/Contact';
import { AboutUs } from './components/Content/about-us/AboutUs';
import ProjectList from './components/Content/projects/ProjectList';
import './App.module.css';
import { ProjectDetails } from './components/Content/projects/ProjectDetails';
import Temp from './components/Content/Temp/Temp';

function App() {
  const currentLanguageCode = localStorage.getItem('i18nextLng') || 'ro';
  return (
    <Routes>
      <Route path="/" element={<Temp />} />
      {/* <Route path="/" element={<Layout />}> */}
      {/*  <Route index element={<Navigate to={currentLanguageCode === 'ro' ? 'proiecte' : 'projects'} />} /> */}
      {/*  <Route path="despre-noi" element={<AboutUs />} /> */}
      {/*  <Route path="about-us" element={<AboutUs />} /> */}
      {/*  <Route path="proiecte" element={<ProjectList />} /> */}
      {/*  <Route path="projects" element={<ProjectList />} /> */}
      {/*  <Route path="contact" element={<Contact />} /> */}
      {/*  <Route path="proiecte/:id" element={<ProjectDetails />} /> */}
      {/*  <Route path="projects/:id" element={<ProjectDetails />} /> */}
      {/* </Route> */}
    </Routes>
  );
}

export default App;
