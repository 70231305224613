import React from 'react';
import './Temp.css';

function Temp() {
  // const img = '/assets/images/projects/project2/main_image.png';
  return (
    <div className="temp">
      {/* <div className="c-temp__img"> */}
      {/*  <img src={img} alt="render hinta design" /> */}
      {/* </div> */}
      <div className="temp__text">
        <div className="temp__text__title">
          {/* <h1>Hinta design</h1> */}
          <img className="temp__text__title__logo" src="/assets/images/logo.png" alt="Hinta" />
        </div>
        <div className="temp__text__contact">
          <p>
            {/* <i className="fa fa-envelope contact-icon" aria-hidden="true" /> */}
            <a href={'&#109;ailto&#58;o&#102;%6&#54;ic&#37;65&#37;4&#48;hin&#37;74%61-de%7&#51;&#37;6&#57;g&#110;%2Ero'}>
              off&#105;c&#101;&#64;hinta-&#100;e&#115;&#105;gn&#46;ro
            </a>
          </p>
          <p>
            {/* <i className="fa fa-phone contact-icon" aria-hidden="true" /> */}
            (+40) 771 121 715
          </p>
        </div>
      </div>
    </div>
  );
}

export default Temp;
